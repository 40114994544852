import { msalInstance } from "./index";
import { InteractionRequiredAuthError, AuthenticationScheme } from '@azure/msal-browser';
import { protectedResources } from "./authConfig";

let api_url = 'https://api.fede.education';

if(process.env.NODE_ENV === "development"){
  api_url = 'http://localhost:8080';
}

const getToken = async () => {
  const account = msalInstance.getActiveAccount();
  
  if (!account) {
      throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
  }
  

  try {
    const response = await msalInstance.acquireTokenSilent({
        account: account[0],
        scopes:  ["https://api.fede.education/.default"]
    });

    return response.accessToken;
  } catch (error) {
      console.log(error)
  }
}

export const fetchData = async (path) => {
  try {
    const accessToken = await getToken();
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`
    headers.append("Authorization", bearer);

    const response = await fetch(`${api_url}${path}`, {
        method: 'GET',
        headers: headers,
    });

    const json = await response.json();

    return json;

  } catch(error) {
    console.error(error)
  }

}

export const postData = async (path, body) => {
    try {
      const accessToken = await getToken();
      const headers = new Headers();
      const bearer = `Bearer ${accessToken}`
      headers.append("Authorization", bearer);
      headers.append("Content-Type", "application/json");
  
      if(process.env.NODE_ENV === "development"){
        console.log(JSON.stringify(body))
      }    

      console.log(api_url + path)
      const response = await fetch(`${api_url}${path}`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body),
      });

      const json = await response.json();

      return json;
    } catch(error) {
      console.error(error)
    }
  
}